<template>
  <b-row class="match-height">
    <b-col lg="12">
      <b-card :title="`PO Summary Chart ${currentBranch}`">
        <b-row>
          <b-col cols="12">
            <b-button
              type="button"
              variant="primary"
              class="mr-1"
              @click="previous()"
            >
              <feather-icon
                icon="ArrowLeftIcon"
                size="16"
                class="align-middle"
              />
              Back
            </b-button>
            <div class="divider" />
          </b-col>
          <b-col cols="12">
            <b-form-group>
              <br />
              <div>
                <b-row>
                  <b-col cols="12" class="text-center font-weight-bold">
                  <h3>Local</h3>
                    <div id="chart">
        <vue-apex-charts type="bar" height="350" :options="chartOptions" :series="series"></vue-apex-charts>
      </div>
                  </b-col>
                  <b-col cols="12" class="text-center font-weight-bold">
                  <h3>Head Office</h3>
                    <div id="chart">
        <vue-apex-charts type="bar" height="350" :options="chartOptions" :series="seriesCenter"></vue-apex-charts>
      </div>
                  </b-col>
                </b-row>
                <p />
              </div>
            </b-form-group>
          </b-col>
        </b-row>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BButton,
  BCard,
} from "bootstrap-vue"
// import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
// import axios from "axios"
// import moment from "moment"
import axios from "axios"
import Ripple from "vue-ripple-directive"
// import { ref } from "@vue/composition-api"
import VueApexCharts from 'vue-apexcharts'

const items = []
const currentBranch = ''
const series = [{
  name: 'Supplies',
  data: [0, 0, 0, 0, 0, 0, 0, 0, 0],
}, {
  name: 'FGS',
  data: [0, 0, 0, 0, 0, 0, 0, 0, 0],
}, {
  name: 'Assets',
  data: [0, 0, 0, 0, 0, 0, 0, 0, 0],
}]
const seriesCenter = [{
  name: 'Supplies',
  data: [0, 0, 0, 0, 0, 0, 0, 0, 0],
}, {
  name: 'FGS',
  data: [0, 0, 0, 0, 0, 0, 0, 0, 0],
}, {
  name: 'Assets',
  data: [0, 0, 0, 0, 0, 0, 0, 0, 0],
}]
export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BButton,
    BCard,
    VueApexCharts,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      currentBranch,
      series,
      seriesCenter,
          chartOptions: {
            chart: {
              type: 'bar',
              height: 350,
            },
            plotOptions: {
              bar: {
                horizontal: false,
                columnWidth: '55%',
                endingShape: 'rounded',
              },
            },
            dataLabels: {
              enabled: false,
            },
            stroke: {
              show: true,
              width: 2,
              colors: ['transparent'],
            },
            xaxis: {
              categories: ['Jan','Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            },
            yaxis: {
              title: {
                text: this.currentBranch,
              },
            },
            fill: {
              opacity: 1,
            },
            tooltip: {
              y: {
                formatter(val) {
                  return `${val} Purchase Orders`
                },
              },
            },
          },
      items,
    }
  },
  mounted() {
    this.getDetail()
    this.getDetailCenter()
  },
  methods: {
    getDetail() {
      const itemString = localStorage.getItem("SummaryData")
      console.log(itemString)
      // Parse the string back to an object
      const item = JSON.parse(itemString)
      this.currentBranch = item.branch_name
      // Ensure that po_numbers exists and is a string before splitting
      if (item.po_numbers && typeof item.po_numbers === 'string') {
        this.items = item.po_numbers.split(",")
      } else {
        this.items = []
      }
      const userToken = this.$cookies.get("userToken")
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
      }
    const url = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_PO_SUMMARY_CHART}/${item.branch_id}?is_center=false`
    axios
      .get(url, { headers })
      .then(responseSet => {
        this.series = responseSet.data.data
      })
    },
    getDetailCenter() {
      const itemString = localStorage.getItem("SummaryData")
      console.log(itemString)
      // Parse the string back to an object
      const item = JSON.parse(itemString)
      this.currentBranch = item.branch_name
      // Ensure that po_numbers exists and is a string before splitting
      if (item.po_numbers && typeof item.po_numbers === 'string') {
        this.items = item.po_numbers.split(",")
      } else {
        this.items = []
      }
      const userToken = this.$cookies.get("userToken")
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
      }
    const url = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_PO_SUMMARY_CHART}/${item.branch_id}?is_center=true`
    axios
      .get(url, { headers })
      .then(responseSet => {
        this.seriesCenter = responseSet.data.data
      })
    },
    previous() {
      this.$router.go(-1)
    },
  },
}
</script>